/* ----- Web platform override ----- */
body {
  background-color: var(--theme-color-text);
  color: var(--theme-color-black);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
button,
li {
  color: var(--theme-color-black);
}
.live-video-container {
  /* display: none; */
}
#bg-video-player {
  height: 100%;
}
#bg-video-player .video-js {
  height: 100%;
}
.page-container.has-show-bg-video {
  transition: margin-top 0.5s ease-in-out;
}
.nav-close-btn {
  display: none;
}

.app-container {
  overflow: auto;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--theme-color-text);
  color: var(--theme-color-black);
}
.top-navigation .nav-left,
.top-navigation .nav-right {
  flex: auto;
  align-items: center;
}
.top-navigation .nav-right {
  padding-right: 25px;
}
.top-navigation {
  background-color: var(--theme-color-body-bg) !important;
}
.top-navigation .tabs .menu-item.focused {
  /* border-bottom: 6px solid var(--theme-color-transparent); */

  box-shadow: 0 6px 0 var(--theme-color-transparent);
}
.top-navigation .tabs .menu-item {
  font-size: 27px;
  white-space: nowrap;
}
.top-navigation {
  left: 0;
  right: 0;
  top: 17px;
  width: 100%;
  height: 66px;
  background-color: none !important;
  z-index: 8888;
}
.page-container .page-content {
  width: 100%;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 0;
}
.page-container .page-content.has-bg-video {
  padding-top: 0px;
}

.video-container .close-video-container {
  display: block;
  border-radius: 29px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999;
  background-color: var(--theme-color-text);
  padding: 2px 7px;
}
.page-container {
  overflow-y: scroll;
  margin-top: 100px;
  min-height: 80vh;
}
#overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-color-landing-img-bg);
}
.top-navigation {
  background-color: transparent !important;
}
.top-navigation.scrolled {
  background: var(--theme-color-black);
}
.landing-page-container {
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  text-align: center;
  width: 100%;
}
.landing-img {
  height: auto;
  width: 100%;
}
.about-us {
  padding: 30px 0;
  background-color: var(--theme-color-text);
}
.about-us .h1 {
  font-size: 6rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
}

.responsive-paragraph {
  line-height: 1.5;
  font-size: 23px;
}
.landing-page-main-container-loading {
  height: 959px;
}
.landing-page-main {
  position: relative;
}

.fullscreen-container-fixed span,
div {
  color: var(--theme-color-text) !important;
}
.video-details-main-div {
  overflow: unset;
  height: unset;
}
.horizontal-list .media-element.portrait {
  position: relative;
  width: 240px;
  margin-top: 20px;
}
.horizontal-list {
  position: relative;
  width: 100%;
  margin-bottom: -9px;
}
.horizontal-list .media-element.portrait .img .img-container img {
  width: 240px;
  aspect-ratio: 2/3;
  height: 360px;
  object-fit: contain;
}
.horizontal-list .media-element.portrait .img {
  width: 240px;
  height: 360px;
}

.horizontal-list .media-element.portrait .img .img-container {
  width: 240px;
  height: 360px;
}
.horizontal-list .media-element {
  padding: 40px 11px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 40px;
}
.horizontal-list .grid-title {
  position: absolute;
  font-size: 27px;
  line-height: 42px;
  top: 12px;
  font-weight: 600;
  margin-left: 25px;
  color: var(--theme-color-black) !important ;
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--theme-color-primary);
  background-size: 35px 35px;
  background-color: var(--theme-color-black) !important;
  transition: 0.3s;
}
.horizontal-list .media-element .title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
  padding: 0px !important;
  margin: 0px !important;
}
.horizontal-list .media-element .sub-title {
  font-size: 19px;
  width: 400px;
  line-height: 28px;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#movies .swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
}
#videos .swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  padding: 202px 32px;
}

.more-like-this .horizontal-list {
  margin-top: -60px;
  width: 100%;
  margin-bottom: 0;
  height: 385px;
}
.fullscreen-container-fixed .video-details {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  margin-bottom: -70px;
  height: 618px;
  scroll-behavior: smooth;
}
.fullscreen-container-fixed .back-to-page {
  display: block;
  position: absolute;
  top: 3px;
  left: 49px;
  color: var(--theme-color-white);
  cursor: pointer;
  font-size: 63px;
  font-weight: 800;
  z-index: 9999;
}
.video.prj-element.landscape {
  height: auto !important;
  padding-bottom: 27px;
  padding-top: 59px !important;
}
.fullscreen-container-fixed .back-to-page svg:hover {
  fill: var(--theme-color-primary);
}
.media-element .img .progress-wrapper {
  position: absolute;
  bottom: 21px;
  left: 1%;
  width: 98%;
  padding: 0 2px;
}
.player-bottom-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 151px;
  height: 205px;
}
/* .media-element.landscape.prj-element .img {
  width: 400px;
  height: 225px;
}
.media-element.landscape.prj-element .img .img-container {
  width: 400px;
  height: 225px;
}
.media-element.landscape.prj-element .img {
  width: 400px;
  height: 225px;
} */
.media-element .img .video-duration {
  position: absolute;
  bottom: 9px;
  left: 0px;
  text-align: right;
  width: 400px;
  font-size: 30px;
  color: var(--theme-color-white);
  padding-right: 38px;
  padding-bottom: 15px;
}
.player-overlay {
  width: 100%;
  height: 100%;
}
.player-bottom-bar {
  bottom: 57px;
  height: 205px;
}
.fastforward.media-btn.prj-element:hover {
  background-color: var(--theme-color-primary);
}
.rewind.media-btn.prj-element:hover {
  background-color: var(--theme-color-primary);
}

.playpause.media-btn.prj-element:hover {
  background-color: var(--theme-color-primary);
}
.media-element .img .video-duration {
  font-size: 20px;
}
#movies .overlay-grid {
  position: absolute;
  transition: 0.5s ease;
  width: 560px;
  height: 129px;
  bottom: 0;
  left: -3px;
  background: unset;
  opacity: 1;
}

.swiper-wrapper .swiper-slide:last-child {
  margin-right: 83px;
}
.extra-margin-div {
  width: 20px;
}
.vjs-control-bar {
  display: flex !important;
}
.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
  height: 3em;
  background-color: var(--theme-color-img-bg);
}
.landing-page-container button {
  color: var(--theme-color-black);
}
.player-overlay {
  display: none;
}
.vjs-progress-control.vjs-control {
  display: flex;
}
#video-player span {
  font-family: VideoJS !important;
}
.swiper-button-next {
  display: flex;
  border-radius: 46px;
  width: 60px;
  height: 60px;
  padding: 0 0;
}

.swiper-button-prev {
  display: flex;
  border-radius: 46px;
  width: 60px;
  height: 60px;
  padding: 0 0;
}

.swiper-button-prev.swiper-button-disabled {
  display: none;
}
.swiper-button-next.swiper-button-disabled {
  display: none;
}
.fullscreen-container-fixed .video-details .details .title {
  font-size: 46px;
  line-height: 78px;
  font-weight: 500;
  margin-top: -5px;
  color: var(--theme-color-text);
}
.more-like-this .horizontal-list .media-element.portrait {
  position: relative;
  width: 240px;
  margin-top: 20px;
  padding: 40px 0px;
}
.video-rating {
  font-size: 14px;
  background-color: hsl(172deg 95% 54% / 27%);
  line-height: 1.7;
  display: inline-block;
  color: var(--theme-color-text);
  margin-bottom: 0;
  line-height: 26px;
  border-radius: 7px;
  margin-left: 5px;
}
.video-rating span {
  padding: 1px 6px;
}
#video .swiper-wrapper {
  margin-bottom: 48px;
}
.media-element .img .progress-wrapper .progress-bar {
  width: 100%;
  background-color: var(--theme-color-landing-bg);
  padding: 0px;
  border-radius: 76px;
  margin: 0px 0px;
}
.media-element .img .progress-wrapper .progress-bar-fill {
  display: block;
  height: 5px;
  background-color: var(--theme-color-primary);
  border-radius: 66px;
}
.search-results.prj-element #video .swiper-wrapper {
  margin-bottom: 36px;
}

#movies .swiper-slide {
  width: 240px !important;
}
#video .swiper-slide {
  width: 400px !important;
}
#more-like-this-videos .swiper-slide {
  width: 240px !important;
}
#more-like-this-movies .swiper-slide {
  width: 400px !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-us .container {
    width: 970px;
  }
  .responsive-paragraph {
    font-size: 20px !important;
    color: var(--theme-color-landing-bg) !important;
    letter-spacing: 1.1px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* Small Screen Rules */
  .about-us .container {
    width: 750px;
  }
  .responsive-paragraph {
    text-align: center;
    font-size: 17px !important;
    letter-spacing: 1.3px;
  }
}
@media (max-width: 767px) {
  .responsive-paragraph {
    font-size: 13px !important;
    letter-spacing: 1.4px;
    text-align: justify;
  }
}
.landing-page-container h1 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 10px;
  color: var(--theme-color-white);
}
.landing-page-container p {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--theme-color-primary);
}
.landing-page-container h3 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-bottom: 20px;
  color: var(--theme-color-white);
}
.landing-page-second-section {
  display: flex;
  max-width: calc(100% - 20rem);
  margin: 0 auto;
  margin-top: 24px;
}
.dropdown-section {
  padding: 4.5rem 0;
  margin: 0 49px;
  max-width: calc(83.33333333333334% - -23rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1280px) {
  .dropdown-section {
    padding: 4.5rem 0;
    margin: 0 49px;
    max-width: calc(83.33333333333334% - -23rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.image-s-2 {
  width: 661px;
  margin-left: 45px;
  margin-top: 31px;
}
.text-s-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-s-2 h2 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.text-s-2 p {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 1rem 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  text-align: justify;
}
.drop-down-li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  margin: 0 0 0.5rem 0;
}
.drop-down-li h3 {
  display: flex;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0.0625rem;
  position: relative;
  background-color: var(--theme-color-primary);
  color: var(--theme-color-white);
  transition-duration: 250ms;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.9, 0, 0.51, 1);
}
.drop-down-li h4 {
  padding: 1.5rem;
  appearance: none;
  background: none;
  border-radius: 0;
  border: 0;
  box-sizing: content-box;
  color: inherit;
  cursor: default;
  display: inline;
  font: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  font-family: inherit;
  font-size: 17px;
  text-align: left;
}
.cross-transition {
  transform: rotate(-45deg);
}
.dropdown-default {
  overflow: hidden;
  max-height: 0;
  visibility: collapse;
  max-height: 0;
  padding: 0 1.5rem;
  -webkit-transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  text-align: left;
  background-color: var(--theme-color-input-bg);
  color: var(--theme-color-white);
}
.dropdown-hidden-section {
  max-height: 75rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.dropdown-section ul {
  padding: 0;
}
.dropdown-hidden-section-visible {
  visibility: visible;
}
.dropdown-section h2 {
  font-size: 6rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;
}
#dropdown-cross-svg {
  display: none;
}
.dropdown-cross-logo {
  width: 2.25rem;
  height: 2.25rem;
  transform: rotate(-45deg);
  flex-shrink: 0;
}
.top-menu-absolute {
  position: absolute;
}
.top-navigation {
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 116px;
  background-color: none !important;
  z-index: 8888;
}
.navbar.colorChange {
  background-color: var(--theme-color-text) !important;
}
.landing-page-underline-section {
  width: 100%;
  height: 0.5rem;
  position: absolute;
  background-color: var(--theme-color-text) white;
}
.footer-main-container {
  /* margin: 0 49px; */
  position: relative;
  background-color: var(--theme-color-text);
}
.footer-rows {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-links-ul {
  display: flex;
  width: 100%;
  list-style: none;
  padding: 0;
}
.footer-links-ul:first-child {
  margin-left: -6px;
}
.footer-links-ul li {
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--theme-color-black);
}
.footer-links-ul li:hover {
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--theme-color-primary);
}
.footer-row-2 .footer-widget-section {
  display: flex;
  justify-content: flex-end;
}
.footer-widget {
  margin-left: 23px;
}
.footer-widget-section:first-child {
  margin-left: -19px;
}
.page-container {
  position: unset;
  height: 100%;
  width: 100%;
  left: -96px;
}

.my-button {
  background-color: transparent;
  border: 10px;
  color: black;
  cursor: pointer;
  font-size: 30px;
  margin-left: 32px;
  float: left;
  line-height: 70px;
  display: none;
}
.fullscreen-container-fixed .video-details .details .buttons {
  display: flex;
  justify-content: space-between;
  width: 522px;
}
.fullscreen-container-fixed .video-details .details .description {
  font-size: 21px;
  line-height: 28px;
  margin-top: 10px;
  height: auto;
  width: 95%;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.fullscreen-container-fixed .video-details .details .buttons .play-btn {
  background-color: var(--theme-color-white);
  color: var(--theme-color-body-bg);
  margin-top: 6px;
  line-height: 0px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 265px;
  height: 50px;
  border-radius: 16px;
}
.fullscreen-container-fixed .video-details .details {
  width: 65%;
  padding-top: 45px;
  padding-left: 50px;
}
.fullscreen-container-fixed .more-like-this {
  position: relative;
  width: 100%;
  top: -116px;
  left: 8px;
}
.fullscreen-container-fixed .more-like-this .title {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  margin-left: 32px;
  position: fixed;
  color: var(--theme-color-text);
}

.fullscreen-container-fixed .video-details .details .buttons .play-btn img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.play-btn.small.prj-element {
  width: 127px !important;
  /* font-size: 23px !important; */
}
.video-details-main-div.videos .video-details .details .description {
  font-size: 17px;
  line-height: 23px;
  margin-top: 10px;
  height: auto;
  width: 78%;
  margin-bottom: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
button#play-btn.play-btn.small.prj-element.focused {
  width: 127px !important;
  background-color: var(--theme-color-white);
}
button#play-btn.play-btn.small.prj-element.focused:hover {
  background-color: var(--theme-color-primary);
}
button#resume-btn.play-btn.prj-element.focused {
  background-color: var(--theme-color-white);
}
button#resume-btn.play-btn.prj-element.focused:hover {
  background-color: var(--theme-color-primary);
}
.beginning-btn {
  width: 239px !important;
}
.page-container:has(.page-content .fullscreen-container-fixed) {
  position: unset;
  height: 0;
  width: 100%;
}
.nav img {
  max-height: 3rem;
}
.loader {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-results {
  padding: 20px 0px 50px 0px;
  width: 100%;
  height: auto;
}
.search-results .searching {
  color: var(--theme-color-black) !important;
}
.search-bar input {
  background-color: var(--theme-color-input-bg);
  color: var(--theme-color-primary);
}
.search-bar input::placeholder {
  color: var(--theme-color-primary);
}
.search-results .loading {
  margin-top: 0;
}
.splash-image-logo-web {
  width: 242px;
  height: 242px;
  object-fit: contain;
}
.splashscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.empty-container {
  width: auto !important;
  height: auto !important;
  overflow: hidden !important;
}
.copy-right-text {
  font-size: 13px;
  color: var(--theme-color-black) !important;
  margin-bottom: 10px;
}
#video:has(.swiper-button-next) {
  top: 20px;
}
#video > .swiper-button-next {
  top: 157px;
}
#video > .swiper-button-prev {
  top: 156px;
}
#more-like-this-movies > .swiper-button-next {
  top: 179px;
}
#more-like-this-movies > .swiper-button-prev {
  top: 179px;
}
.fullscreen-container-fixed .video-details .image-wrap.portrait {
  width: 288px;
  height: 425px;
  margin-top: 0px;
}

.landing-loader-container {
  height: 978px;
}
.landing-page-explore-btn:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-text);
}
.landing-page-main {
  height: 100vh;
  background: transparent;
  /* background-image: url('../images/disabilityownedwebsitestillsandscreenshot/shaking-hands-with-disabled-businessman-in-wheelchair-outdoor-in-SBV-337819973-HD0 (1).png'); */
  background-repeat: no-repeat;
  background-size: cover;
}
#video.grid-title {
  top: 29px;
}
@media only screen and (min-width: 321px) and (max-width: 1400px) {
  nav .tabs {
    display: none;
    flex-direction: column;
    width: 100%;
  }
  .nav-close-btn {
    display: block;
    background: var(--theme-color-primary);
  }

  .nav-close-btn svg {
    transform: rotate(-45deg);
  }
  .my-button {
    display: flex;
    fill: var(--theme-color-text);
    cursor: pointer;
  }
  .tabs > .dropdown > summary,
  .tabs > a {
    padding: 0;
  }
  .tabs.show-top-menubar {
    display: block;
    position: relative;
  }
  .top-navigation .tabs .menu-item {
    font-size: 13px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 1400px) {
  .landing-page-second-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 15px 20px;
    max-width: inherit;
  }
  .text-s-2 h2 {
    font-size: 3rem;
    font-weight: 900;
  }
  .text-s-2 p {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
  }
  .landing-page-container h1 {
    font-size: 2rem;
    font-weight: 900;
    color: var(--theme-color-primary);
  }
  .landing-page-container p {
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1;
  }
  .landing-page-container h3 {
    font-size: 1.5rem;
  }
  .footer-rows {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
  }
  .footer-links-ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  nav .tabs {
    display: none;
    flex-direction: column;
    width: 44%;
  }

  .my-button {
    display: flex;
    fill: black;
    cursor: pointer;
  }
  .tabs > .dropdown > summary,
  .tabs > a {
    padding: 0;
  }
  .tabs.show-top-menubar {
    display: block;
    right: 300px;
    top: 189px;
    padding: 22px;
    position: relative;
    background-color: white;
  }
  .top-navigation .tabs .menu-item {
    font-size: 26px;
  }
  .fullscreen-container-fixed .video-details {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 200px;
    align-items: center;
    justify-content: center;
    margin-bottom: 265px;
  }
  .fullscreen-container-fixed .video-details .details {
    width: 65%;
    padding-top: 79px;
    text-align: center;
    padding-left: 0;
  }
  .fullscreen-container-fixed .video-details .image {
    width: auto;
    padding-right: 0;
    text-align: right;
  }
  .fullscreen-container-fixed {
    position: absolute;
  }
  .fullscreen-container-fixed .video-details .details .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }
  .fullscreen-container-fixed .video-details .details .buttons .play-btn {
    margin-top: 22px;
  }
  .player-progress-track {
    display: flex;
    width: 1234px;
    height: 10px;
    background-color: var(--theme-color-landing-bg);
    border-radius: 25px;
  }
  .player-bottom-bar {
    height: auto;
  }
}
.tabs.show-top-menubar {
  display: block;
}
.fullscreen-container-fixed .video-details .image {
  /* width: 35%;
  padding-right: 50px;
  text-align: right; */
}
@media screen and (max-width: 480px) {
  .nav-center,
  .nav-left,
  .nav-right {
    flex-wrap: nowrap;
    justify-content: center;
  }
  .nav,
  .nav > .container {
    flex-direction: row;
  }
  .landing-page-container {
    position: relative;
    top: calc(42% - 24px);
    left: 0;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width: 321px) and (max-width: 1300) {
  .fullscreen-container-fixed .video-details {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 223px;
    align-items: center;
    justify-content: center;
    margin-bottom: 153px;
  }
  .fullscreen-container-fixed .video-details .details {
    width: 65%;
    padding-top: 79px;
    text-align: center;
    padding-left: 100px;
  }
}

@media screen and (max-width: 1280px) {
  .player-progress-track {
    width: 793px;
  }
  .player-button-group {
    width: 941px;
  }
}
@media screen and (max-width: 800px) {
  .player-progress-track {
    width: 521px;
  }
  .player-button-group {
    width: 794px;
  }
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: var(--theme-color-primary) !important;
  color: black !important;
}

.swiper-wrapper {
  transition-duration: 2s !important;
  transition-delay: 0ms;
  margin-left: 16px;
}
#more-like-this-videos {
  height: 400px;
}
.error-text-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 21px;
  justify-content: center;
  font-size: 20px;
}
.error-title {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.splashscreen .loader {
  height: 0;
}
.close-video-container svg {
  fill: var(--theme-color-black) !important;
  position: relative;
  bottom: -3px;
  cursor: pointer;
}
.close-video-container:hover {
  background-color: var(--theme-color-primary) !important;
}
.vjs-loading-spinner:before,
.vjs-loading-spinner:after {
  border-color: var(--theme-color-primary);
  border-top-color: var(--theme-color-text);
}
.splashscreen .loading-name {
  display: none;
}
.splashscreen .dots {
}
.video-details-duration {
  margin-left: 5px;
  margin-right: 7px;
}

.web-loader-spalsh-screen {
  width: 87px;
  /* height: 60px; */
  animation: rotation 1s linear infinite;
  filter: brightness(0) saturate(100%) invert(11%) sepia(100%) saturate(5567%)
    hue-rotate(226deg) brightness(92%) contrast(91%);
}
.video-details-release-date {
  margin-left: 5px;
  margin-right: 7px;
}
.detail-page-actor1,
.detail-page-actor2,
.detail-page-actor3 {
  margin-left: 5px;
  margin-right: 5px;
}
span.detail-page-vertical-line {
  font-weight: 800;
}
.actor-section {
  margin-bottom: 10px;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img.landing-s-2-pic {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.text-s-2 {
  margin: 0 45px;
  width: 50%;
}
.landingpage-text-container {
  margin-bottom: 41px;
  display: flex;
}
.top-navigation .brand-logo img {
  width: 131px;
  height: 76px;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(89%) sepia(17%) saturate(0%)
    hue-rotate(196deg) brightness(111%) contrast(101%);
}
.horizontal-list .media-element.active .img,
.horizontal-list .media-element.focused .img,
.horizontal-list .media-element:hover .img {
  box-shadow: 0px 0px 10px 10px var(--theme-color-primary);
}
.top-navigation .tabs .menu-item.active {
  color: var(--theme-color-primary);
}
.top-navigation .icon.focused,
.top-navigation .icon:hover {
  /* border-bottom: 6px solid var(--theme-color-primary); */

  box-shadow: 0 6px 0 var(--theme-color-primary);
}
.fullscreen-container-fixed .video-details .details .buttons .play-btn:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-text);
}
.loader .loader-text {
  color: var(--theme-color-primary) !important;
  z-index: 99;
}
.loader .loader-text .dots {
  background-color: var(--theme-color-primary) !important;
  z-index: 99;
}
.video-js .vjs-play-progress {
  background-color: var(--theme-color-primary);
}
.top-navigation .tabs .menu-item {
  color: var(--theme-color-white);
}
.top-navigation .tabs .menu-item.focused,
.top-navigation .tabs .menu-item:hover {
  /* border-bottom: 6px solid var(--theme-color-primary); */

  box-shadow: 0 6px 0 var(--theme-color-primary);
}
div#page-container-main {
  margin-top: 115px;
  height: 1200px;
}
.top-navigation .icon.active img {
  filter: drop-shadow(0px 0px var(--theme-color-primary));
}
.bg-player-overlay-opacity {
  background-color: var(--theme-color-landing-bg);
}
.dropdown-hidden-section-visible {
  color: var(--theme-color-black) !important;
}
.scroll-top-menu {
  color: var(--theme-color-primary) !important;
}
.brand-logo-scroll {
  filter: drop-shadow(0px 0px var(--theme-color-primary)) !important;
}
.brand-logo-home {
  filter: brightness(0) saturate(100%) invert(16%) sepia(73%) saturate(4005%)
    hue-rotate(221deg) brightness(96%) contrast(96%) !important;
}

.media-element .landscape .img {
  max-width: 100%; /* Ensures the image container doesn't exceed the width of its parent */
  position: relative; /* Needed for the aspect ratio technique */
}

.media-element .landscape .img::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 or 56.25% for a 16:9 aspect ratio */
}

.media-element .landscape .img .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}